export const en = {
    //Navigation Bar
    'n1': 'Home',
    'n2': 'Order',
    'n3': 'Transaction',
    'n4': 'Team',
    'n5': 'User',
    //prompt class
    't1': 'An error occurred',
    't2': 'Copy successful',
    't3': 'Please fill in the information completely',
    't4': 'Loading...',
    't5': 'Login successful',
    't6': 'The score must be greater than 3 to submit',
    't7': 'Submission successful',
    't8': 'Insufficient balance, unable to receive',
    't9': 'Submission failed',
    't10': 'Please bind the withdrawal address first',
    't11': 'The address has been bound, please contact customer service to modify it',
    't12': 'Modification successful',
    't13': 'Modification failed',
    't14': 'Please contact customer service to change your password',
    't15': 'Password format error',
    't16': 'Withdrawal successful',
    't17': 'Maximum withdrawals',
    't18': 'Minimum withdrawal',
    't19': 'Two passwords are inconsistent',
    't20': 'Prompt',
    't21': 'Cannot jump temporarily',
    't22': 'Password format error',
    't23': 'Registration successful',
    't24': 'Please enter the area code',
    't25': 'No more',
    //sign up
    'l1': 'Language',
    'l2': 'Please enter your account number',
    'l3': 'Please enter password',
    'l4': 'remember',
    'l5': 'Login',
    'l6': 'Go to registration',
    'l7': 'No account',
    'l8': 'Mobile phone number',
    'l9': 'nickname',
    'l10': 'password',
    'l11': 'Confirm password',
    'l12': 'invitation code',
    'l13': 'Register',
    'l14': 'Already have an account',
    'l15': 'Go to log in',
    'l16': 'Please enter a 6-16 digit password',

    //help
    'bz1': 'Help',
    'bz2': 'FAQ',
    'bz3': 'Trading rules',
    'bz4': 'How to withdraw money',
    'bz5': 'How to recharge',
    'bz6': '1.What is ebay Review RoBoT?',
    'bz7': "ebay Review RoBoT is a marketing and promotion company that helps ebay Review RoBoT online store merchants around the world get more order sales and increase the browsing data of ebay Review RoBoT online stores. We are committed to establishing a tripartite profitable marketing and promotion model between ebay Review RoBoT, merchants and consumers. Combined with the latest P2P blockchain technology, consumers and merchants are quickly connected through USDT (TRC20, ERC20). Registered users can get commissions on orders, while merchants can increase their store's sales data.It is the latest profit model in the Internet blockchain model! ",
    'bz8': '2.How does ebay Review RoBoT work?',
    'bz9': 'The daily feedback from ebay Review RoBoT shows that products that need improvement are sold in ebay Review RoBoT. Users only need to easily click on the order, and the system automatically generates an order subscription. Users pay the order amount via blockchain USDT and receive daily commissions. ',
    'bz10': '3. Why are the prices of the same currency different in two transactions? ',
    'bz11': 'Price differences are caused by all factors that are not conducive to the free circulation of currencies, including the speed of currency transfers, network conditions, currency access restrictions, currency recognition by people in different regions, and even the trading pairs provided by exchanges types, transactions, etc. Therefore, the same currency may have price differences in different transactions. ',
    'bz12': '4. Investment profit? ',
    'bz13': 'The higher the product price you get, the higher the order profit you get. At the same time, ebay Review RoBoT randomly distributes large commission orders. ',
    'bz14': 'Every day, the system automatically generates and distributes 60 product orders to users. Users complete the payment for each order through USDT and receive a 0.6% commission. Large commission orders are randomly assigned. ',
    'bz15': 'USDT can be withdrawn after 60 orders. (If 60 orders are not completed, the system will automatically stop until the remaining orders of the day)',
    'bz16': 'Each account can only be bound to one USDT account. If an error occurs, please contact customer service',
    'bz17': 'After completing the delivery of daily orders, you can withdraw money normally. It will arrive within 24 hours after starting the withdrawal, and there is no withdrawal limit',
    'bz18': 'Please bind your withdrawal address (supports TRC-20) USDT address before recharging. You can click Recharge on the APP or web page and select the blockchain (TRC-20) you use to recharge. ',
    'bz19': "It is forbidden to top up or transfer funds between related accounts. Such behavior is suspected of malicious order grabbing. If such behavior is found, the account will be banned.",
    //module text
    //Common words
    'ty1': 'OK',
    'ty2': 'Cancel',
    'ty3': 'Submit',
    'ty4': 'address',
    //front page
    's1': 'Cumulative income',
    's2': 'Service',
    's3': 'About us',
    's4': 'Help',
    's5': 'Partner',
    's6': 'My service',
    //Order
    'd1': 'Unfinished',
    'd2': 'Completed',
    'd3': 'No data yet',
    'd4': 'odd number',
    'd5': 'Trading time',
    'd6': 'amount',
    'd7': 'Current number of tasks',
    'd8': 'Expected return',
    'd9': 'Need more',
    'd10': 'Rating',
    //trade
    'j1': 'Account balance',
    'j2': 'Trading introduction',
    'j3': "ebay Review RoBoT uses ebay Review RoBoT's feedback every day to show the products sold by ebay Review RoBoT that need improvement.Users only need to click on the order and the system will automatically generate an order signature.Users pay the order amount through the USDT blockchain and can receive more than 0.6% commission each time, and the system randomly distributes large reward orders. ",
    'j4': 'Start matching',
    'j5': "Today's earnings",
    'j6': 'Number completed',
    'j7': 'Total number of tasks',
    //team
    'td1': 'Balance',
    'td2': 'commission',
    'td3': 'Receive',
    'td4': 'Total number of people',
    'td5': 'user',
    'td6': 'contribution',
    'td7': 'Quantity',
    'td8': 'status',
    //Personal center
    'g1': 'Withdraw',
    'g2': 'Recharge',
    'g3': 'Invite friends',
    'g4': 'Invite now',
    'g5': 'For every profit your friend makes after registering, you will receive a corresponding proportion of commission',
    'g6': 'Personal information',
    'g7': 'Fund details',
    'g8': 'Recharge record',
    'g9': 'Withdrawal record',
    'g10': 'Language',
    'g11': 'Log out',
    'g12': 'Are you sure you want to log out?',
    //User Info
    'yh1': 'User information',
    'yh2': 'Modify',
    'yh3': 'setting',
    'yh4': 'telephone',
    'yh5': 'Are you sure you want to log out?',
    'yh6': 'Original password',
    'yh7': 'New password',
    //Details
    'mx1': 'Commission collection',
    'mx2': 'profit',
    'mx3': 'Withdrawal rejected',
    'mx4': 'time',
    'mx5': 'Recharge successful',
    'mx6': 'Rejected',
    'mx7': 'pending',
    'mx8': 'Withdrawal address',
    'mx9': 'Available balance',
    'mx10': 'Actual arrival',
    'mx11': 'Please enter the withdrawal amount',
    //top up
    'cz1': 'The deposit address only supports ERC20, and the minimum deposit amount is 20USDT',
    'cz2': 'Copy',
    'cz3': 'Fiat currency selection',
    'cz4': 'payment',
    'cz5': 'The deposit address only supports TRC20-USDT, and the minimum deposit amount is 20USDT',
    'cz6': 'The deposit address only supports ERC20, and the minimum deposit amount is 20USDT',
    'cz1': 'The deposit address only supports ERC20, and the minimum deposit amount is 20USDT',
    'cz1': 'The deposit address only supports ERC20, and the minimum deposit amount is 20USDT',
    "cz10": "Deposit address only supports BTC",
    //area
    'qy1': 'area code',
    'yq1': 'Invite friends',
    'yq2': 'invitation code',
    'yq3': 'Invite friends and enjoy large benefits',
    'yq4': 'Go and invite',
    "new1":"Payment method"
}