export const ru = {
    //Панель навигации
    'n1': 'Дом',
    'n2': 'Заказ',
    'n3': 'Транзакция',
    'n4': 'Команда',
    'n5': 'мой',
    //подсказка класса
    't1': 'Произошла ошибка',
    't2': 'Копирование успешно',
    't3': 'Пожалуйста, заполните информацию полностью',
    't4': 'Загрузка...',
    't5': 'Вход успешен',
    't6': 'Для отправки оценка должна быть больше 3',
    't7': 'Отправка успешна',
    't8': 'Недостаточно средств, невозможно получить',
    't9': 'Отправка не удалась',
   't10': 'Сначала привяжите адрес вывода',
    't11': 'Адрес привязан, обратитесь в службу поддержки клиентов, чтобы изменить его',
    't12': 'Модификация прошла успешно',
    't13': 'Модификация не удалась',
    't14': 'Пожалуйста, обратитесь в службу поддержки клиентов, чтобы изменить пароль',
    't15': 'Ошибка формата пароля',
    't16': 'Вывод выполнен успешно',
    't17': 'Максимальная сумма вывода',
    't18': 'Минимальный вывод',
    't19': 'Два пароля несовместимы',
    't20': 'Подсказка',
    't21': 'Временно невозможно прыгать',
    't22': 'Ошибка формата пароля',
    't23': 'Регистрация прошла успешно',
    't24': 'Пожалуйста, введите код города',
    't25': 'Больше нет',
    //зарегистрироваться
    'l1': 'Язык',
    'l2': 'Пожалуйста, введите номер вашего счета',
    'l3': 'Пожалуйста, введите пароль',
    'l4': 'помни',
    'l5': 'Войти',
    'l6': 'Перейти к регистрации',
    'l7': 'Нет учетной записи',
    'l8': 'Номер мобильного телефона',
    'l9': 'псевдоним',
    'l10': 'пароль',
    'l11': 'Подтвердить пароль',
    'l12': 'пригласительный код',
    'l13': 'Регистрация',
    'l14': 'Учетная запись уже есть',
    'l15': 'Перейти для входа',
    'l16': 'Пожалуйста, введите пароль из 6–16 цифр',

    //помощь
    'bz1': 'Помощь',
    'bz2': 'Часто задаваемые вопросы',
    'bz3': 'Правила торговли',
    'bz4': 'Как вывести деньги',
    'bz5': 'Как перезарядить',
    'bz6': '1. Что такое обзор eBay RoBoT? ',
    'bz7': 'ebay Review RoBoT - это компания по маркетинговому продвижению, которая помогает продавцам интернет-магазинов ebay Review RoBoT по всему миру получать больше продаж и увеличивать данные просмотра интернет-магазинов ebay Review RoBoT. Мы стремимся создать трехстороннюю прибыльную модель маркетинга и продвижения между ebay Review RoBoT, продавцами и потребителями. В сочетании с новейшей технологией блокчейна P2P потребители и продавцы быстро соединяются через USDT (TRC20, ERC20). Зарегистрированные пользователи могут получать комиссионные за заказы, а продавцы могут увеличивать данные о продажах своего магазина. Это новейшая модель получения прибыли в модели интернет-блокчейна! ',
    'bz8': 'Обзор 2.ebay Как работает RoBoT? ',
    'bz9': 'Ежедневные отзывы от ebay Review RoBoT показывают, что продукты, нуждающиеся в улучшении, продаются в ebay Review RoBoT. Пользователям нужно только легко нажать на заказ, и система автоматически генерирует подписку на заказ. Пользователи оплачивают сумму заказа через блокчейн USDT и получают ежедневные комиссии. ',
    'bz10': '3. Почему цены одной и той же валюты в двух транзакциях различаются? ',
    'bz11': 'Ценовые различия вызваны всеми факторами, которые не способствуют свободному обращению валют, включая скорость валютных переводов, сетевые условия, ограничения доступа к валюте, признание валюты людьми в разных регионах и даже торговые пары. предоставляемые типами обменов, транзакциями и т. д. Поэтому одна и та же валюта может иметь разницу в цене в разных транзакциях. ',
    'bz12': '4. Инвестиционная прибыль? ',
    'bz13': 'Чем выше цена продукта, тем выше прибыль от заказа. В то же время ebay Review RoBoT случайным образом распределяет заказы с большой комиссией. ',
    'bz14': 'Каждый день система автоматически генерирует и распределяет среди пользователей 60 заказов на продукцию. Пользователи совершают оплату каждого заказа через USDT и получают комиссию в размере 0,6%. Заказы на крупную комиссию распределяются случайным образом. ',
    'bz15': 'USDT можно вывести после 60 ордеров. (Если 60 заказов не будут выполнены, система автоматически остановится до тех пор, пока не будут выполнены оставшиеся заказы дня)',
    'bz16': 'Каждая учетная запись может быть привязана только к одной учетной записи USDT. В случае возникновения ошибки обратитесь в службу поддержки клиентов',
    'bz17': 'После завершения доставки ежедневных заказов вы можете вывести деньги в обычном режиме. Они поступят в течение 24 часов после начала вывода, и лимит на вывод отсутствует',
    'bz18': 'Пожалуйста, привяжите свой адрес вывода (поддерживает TRC-20) к адресу USDT перед пополнением счета. Вы можете нажать «Пополнить счет» в приложении или на веб-странице и выбрать блокчейн (TRC-20), который вы используете для пополнения счета. ',
    'bz19':"Запрещается осуществлять взаимные пополнения, банковские переводы, обменные переводы и т.п. между связанными аккаунтами. Такое поведение подозревается в злонамеренном перехвате заказов. При обнаружении такого поведения аккаунт будет заблокирован.",
    //текст модуля
    //общие слова
    'ty1': 'ОК',
    'ty2': 'Отменить',
    'ty3': 'Отправить',
    'ty4': 'адрес',
    //титульная страница
    's1': 'Совокупный доход',
    's2': 'Сервис',
    's3': 'О нас',
    's4': 'Помощь',
    's5': 'Партнер',
    's6': 'Мой сервис',
    //Заказ
    'd1': 'Незаконченный',
    'd2': 'Завершено',
    'd3': 'Данных пока нет',
    'd4': 'нечетное число',
    'd5': 'Время торговли',
    'd6': 'Сумма',
    'd7': 'Текущее количество задач',
    'd8': 'Ожидаемый доход',
    'd9': 'все еще нужно',
    'd10': 'Рейтинг',
    //торговля
    'j1': 'Баланс счета',
    'j2': 'Введение в торговлю',
    'j3': 'ebay Review RoBoT использует отзывы ebay Review RoBoT каждый день, чтобы показать продукты, продаваемые ebay Review RoBoT, которые нуждаются в улучшении. Пользователям нужно только нажать на заказ, и система автоматически сгенерирует подпись заказа. Пользователи оплачивают сумму заказа через блокчейн USDT и могут каждый раз получать комиссию более 0,6%, а система случайным образом распределяет заказы с крупным вознаграждением. ',
    'j4': 'Начать сопоставление',
    'j5': 'Сегодняшний доход',
    'j6': 'Номер завершен',
    'j7': 'Общее количество задач',
    //команда
    'td1': 'Баланс',
    'td2': 'комиссия',
    'td3': 'Получить',
    'td4': 'Общее количество людей',
    'td5': 'пользователь',
    'td6': 'вклад',
    'td7': 'Количество',
    'td8': 'статус',
    //Персональный центр
    'g1': 'Снять',
    'g2': "Перезарядка",
    'g3': 'Пригласить друзей',
    'g4': 'Пригласить сейчас',
    'g5': 'За каждую прибыль, полученную вашим другом после регистрации, вы получите соответствующую долю комиссии',
    'g6': 'Личная информация',
    'g7': 'Детали фонда',
    'g8': 'Запись пополнения',
    'g9': 'Запись о снятии средств',
    'g10': 'Язык',
    'g11': 'Выйти',
    'g12': 'Вы уверены, что хотите выйти из системы?',
    //Информация о пользователе
    'yh1': 'Информация о пользователе',
    'yh2': 'Изменить',
    'yh3': 'настройка',
    'yh4': 'Телефон',
    'yh6': 'Исходный пароль',
    'yh7': 'Новый пароль',
    //Подробности
    'mx1': 'Сбор комиссии',
    'mx2': 'прибыль',
    'mx3': 'Вывод средств отклонен',
    'mx4': 'время',
    'mx5': 'Пополнение прошло успешно',
    'mx6': 'Отклонено',
    'mx7': 'в ожидании',
    'mx8': 'Адрес вывода',
    'mx9': 'Доступный баланс',
    'mx10': 'Фактическое прибытие',
    'mx11': 'Пожалуйста, введите сумму вывода',
    //пополнить
    'cz1': 'Адрес депозита поддерживает только ERC20, а минимальная сумма депозита составляет 20 долларов США',
    'cz2': 'Копировать',
    'cz3': 'Выбор фиатной валюты',
    'cz4': 'платеж',
    'cz5': 'Адрес депозита поддерживает только TRC20-USDT, а минимальная сумма депозита составляет 20USDT',
    'cz6': 'Адрес депозита поддерживает только ERC20, а минимальная сумма депозита составляет 20 долларов США',
    'cz1': 'Адрес депозита поддерживает только ERC20, а минимальная сумма депозита составляет 20 долларов США',
    'cz1': 'Адрес депозита поддерживает только ERC20, а минимальная сумма депозита составляет 20 долларов США',
    "cz10": "Адрес депозита поддерживает только BTC",
    //область
    'qy1': 'код города',
    //приглашать
    'yq1': 'Пригласить друзей',
    'yq2': 'пригласительный код',
    'yq3': 'Приглашайте друзей и получайте большие преимущества',
    'yq4': 'Иди и пригласи',
    "new1":"Способ оплаты"
}